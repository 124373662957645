import { MenuItem } from './MenuItem'

export const Navigation = ({ list, user, logout }) => (
    <ul className="divide-y divide-grey-50 pt-10 px-3">
        {list.map((item, i) => {
            if(item.name == "Login"){
                if(user){
                    return(
                        <MenuItem i={i} key={i} item={item} user={user} logout={logout} />
                    );
                }
                else{
                    return(
                        <MenuItem i={i} key={i} item={item} />
                    );
                }
            }
            else{
                return(
                    <MenuItem i={i} key={i} item={item} />
                );
            }
        })}
    </ul>
);