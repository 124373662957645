import useSWR from 'swr'
import axios from '@/lib/axios'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
    const router = useRouter()

    const { data: user, error, mutate } = useSWR(process.env.API_URL+'/dXNlcg', () =>
        axios
            .get(process.env.API_URL+'/dXNlcg')
            .then(res => {
                if(res.data.status != 401){
                    return res.data;
                }
            })
            .catch(error => {
                //if (error.response.status !== 409) throw error
                //router.push('/verify-email')
            }),
            // {
            //     revalidateOnFocus: false,
            //     revalidateOnMount:false,
            //     revalidateOnReconnect: true,
            //     refreshWhenOffline: false,
            //     refreshWhenHidden: false,
            //     refreshInterval: 0
            //   }
    )

    const csrf = () => axios.get('/sanctum/csrf-cookie')

    const register = async ({ setFormErrors, setFormStatus, ...props }) => {
        await csrf()

        setFormErrors([])
        axios
            .post(process.env.API_URL+'/register', props)
            .then(response => {
                setFormStatus(response.status);
                mutate();
                router.push(redirectIfAuthenticated)
            })
            .catch(error => {
                //if (error.response.status !== 422) throw error

                setFormErrors(error.response.data.errors)
            })
    }

    const login = async ({ setFormErrors, setFormStatus, ...props }) => {
        await csrf()
        setFormErrors([])
        await axios
                .post(process.env.API_URL+'/login', props)
                .then(response => {
                    setFormStatus(response.status);
                    mutate();
                    router.push(redirectIfAuthenticated);
                })
                .catch(error => {
                    //if (error.response.status !== 422) throw error
                    setFormErrors(error.response.data.errors)
                })
    }

    const forgotPassword = async ({ setFormErrors, setFormStatus, email }) => {
        await csrf()

        setFormErrors([])
        setFormStatus(null)

        axios
            .post(process.env.API_URL+'/forgot-password', { email })
            .then(response => setFormStatus(response.data.status))
            .catch(error => {
                //if (error.response.status !== 422) throw error

                setFormErrors(error.response.data.errors)
            })
    }

    const resetPassword = async ({ setFormErrors, setFormStatus, ...props }) => {
        await csrf()

        setFormErrors(null)
        setFormStatus(null)
        axios
            .post(process.env.API_URL+'/reset-password', { ...props, token: router.query.token  })
            .then(response => {
                setFormStatus(response);
                //router.push('/login?reset=' + btoa(response.data.status))
            })
            .catch(error => {
                //if (error.response.status !== 422) throw error
                setFormErrors(error.response)
            })
    }

    const resendEmailVerification = ({ setFormStatus }) => {
        axios
            .post(process.env.API_URL+'/email/verification-notification')
            .then(response => setFormStatus(response.data.status))
    }

    const logout = async () => {
        if (! error) {
            await axios
                .post(process.env.API_URL+'/logout')
                .then(() => mutate())
        }
        //router.push('/login')
        window.location.pathname = '/login'
    }

    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) router.push(redirectIfAuthenticated)
        //if (window.location.pathname === "/verify-email" && user?.email_verified_at) router.push(redirectIfAuthenticated)
        if (middleware === 'auth' && error) logout()
    }, [user, error]);

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
    }
}