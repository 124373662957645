import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.API_MAIN_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    headers :{
        Authorization : (typeof window !== 'undefined') ? localStorage.getItem("user") ? 'Bearer '+JSON.parse(localStorage.getItem("user")).token : '' : ''
    }
})

export default axios
