import { Dialog, DialogContent, DialogTitle} from "@mui/material";
import BookingInfoForm from "../Form";

export default function BookingDialog({ openDialog, closeDialog, fullScreen = true}){

    return(
        <Dialog
            className={``}
            fullWidth
            maxWidth={'sm'}
            open={openDialog}
            onClose={closeDialog}
            sx={
                {
                    '& .MuiPaper-root': {
                        width: '100%',
                        margin: `${fullScreen ? '4px': '10px'}`,
                    },
                }
            }
        >
            <div className="block absolute top-0 left-0 w-full h-full bg-[url('/assets/slides/size/jpg/2.jpg')] opacity-30 bg-cover bg-no-repeat"></div>
            <DialogTitle className="relative flex flex-col">
                <label className="font-bold tracking-tight text-gray-900">Get FREE Estimate for your Car Service</label>
            </DialogTitle>
            <DialogContent className='relative flex flex-col md:px-4 px-2'>
                <BookingInfoForm/>
            </DialogContent>
        </Dialog>
    )
}