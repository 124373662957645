/* eslint-disable @next/next/no-img-element */
import { useEffect, useState, useRef } from 'react'
import Link from 'next/link'
import { MenuToggle } from './MenuToggle'
import { Navigation } from './Navigation'
import { Subnavigation } from './Subnavigation'
import { motion, useScroll, useCycle } from "framer-motion";
import { useRouter } from 'next/router'
import { useAuth } from '@/hooks/auth'
import LoadingButton from '../LoadingButton'
import Image from 'next/image'
import BookingDialog from '../Booking/DialogForm'
import AdsBanner from './AdsBanner'
import { Fragment } from 'react'

const navigation = [
    { name: 'About Us', href: '/about-us', current: true },
    // { name: 'Rapide Club', href: '/rapide-club', current: false },
    { name: 'Be A Partner', href: '/be-a-fleet-partner', current: false },
    { name: 'Franchise Us', href: '/franchise-us', current: false },
    { name: 'Contact Us', href: '/contact-us', current: false },
    { name: 'Branch Locator', href: '/branch-locator', current: false },
    { name: 'Buy Tires', href: `${process.env.GULONGPH_LINK}?utm_source=rapide.ph`, current: false },
    //{ name: 'Login', href: '/login', current: false },
]

const sidebar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2
        }
    }),
    closed: {
        clipPath: "circle(30px at 310px 50px)",
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};

const route_list_for_nav_fix = [
    '/book/service',
    '/club/sign-up',
    '/estimator',
    '/checkout',
    '/status',
];

const Navbar = () => {
    const router = useRouter()
    const { user, logout } = useAuth({ middleware: 'auth' });
    const [showBookingDialog, setShowBookingDialog] = useState(false);
    const [isOpen, toggleOpen] = useCycle(false, true)
    const [navPosFix, setNavPosFix] = useState(true)
    const [isLoggingout, setIsLoggingout] = useState(false)
    const refNav = useRef(null)
    const { scrollYProgress } = useScroll({
        target: refNav,
        offset: ["end end", "start start"]
    })
    const { scrollY } = useScroll()

    const [scrollOnNav, setScrollOnNav] = useState(false)
    useEffect(() => {
        return scrollYProgress.onChange((latest) => {
            if(latest == 1) setScrollOnNav(true)
            if(route_list_for_nav_fix.includes(router.route)) setScrollOnNav(true)
        })
    }, [scrollYProgress, router.route])

    useEffect(() => {
        return scrollY.onChange((y) => {
            if(y == 0) setScrollOnNav(false)
            if(route_list_for_nav_fix.includes(router.route)) setScrollOnNav(true)
        })
    }, [scrollY, router.route])

    useEffect(() => {
        if(route_list_for_nav_fix.includes(router.route)) setNavPosFix(false)
    }, [router.route])

    return(
        <Fragment>
            <header className={`${navPosFix ? 'fixed': 'relative'} top-0 w-full z-30 transition-all px-0 snap-start border-b border-black/25 ${(scrollOnNav) ? 'drop-shadow-lg transition-all bg-black/75' : 'drop-shadow-lg transition-all md:bg-black/30 bg-black'}`}>
                <AdsBanner
                    openDialog={()=>setShowBookingDialog(true)}
                    closeDialog={()=>setShowBookingDialog(false)}
                />
                <BookingDialog
                    openDialog={showBookingDialog}
                    closeDialog={()=>setShowBookingDialog(false)}
                />
                <nav className={`max-w-screen-lg mx-auto px-4 lg:grid grid-flow-col py-3 hidden ${(scrollOnNav) ? "xbg-rapide-yellow/25" : "xbg-rapide-yellow"}`}>
                    {!navPosFix && (
                        <div className="py-2">
                            <Link className="lg:hidden flex navbar-brand min-w-max" href="/">
                                <Image
                                    className='mx-auto'
                                    src={'/assets/images/Rapide-Yellow-Logo_135.webp'}
                                    alt={'rapide-logo'}
                                    width={'135'}
                                    height={'51'}
                                />
                            </Link>
                        </div>
                    )}
                    <div className="hidden lg:flex">
                        <Link className="navbar-brand min-w-max" href="/">
                            <Image
                                src={'/assets/images/Rapide-Yellow-Logo_135.webp'}
                                alt={'rapide-logo'}
                                width={'135'}
                                height={'51'}
                            />
                        </Link>
                    </div>
                    <ul className="hidden lg:flex mx-auto items-center uppercase">
                        {navigation.map((nav, index) => {
                            if(nav.name == "Login"){
                                if(user){
                                    return(
                                        <li key={index}>
                                            <Link
                                                href="/bookings"
                                                onClick={(e) => {
                                                    router.push('/bookings')
                                                }}
                                                className={`inline-flex items-center justify-center font-bold whitespace-nowrap lg:px-2 px-1 py-2 lg:mx-1 mx-0 cursor-pointer animation-hover relative hover:text-rapide-red ${(scrollOnNav) ? 'text-rapide-yellow' : 'text-rapide-yellow'}`}>
                                                {isLoggingout ? (
                                                    <LoadingButton classN={'w-5 h-5 mr-2'} label={'Logging out...'}/>
                                                ) : (
                                                    <span>Profile</span>
                                                )}
                                            </Link>
                                        </li>
                                    );
                                }
                                else{
                                    return(
                                        <li key={index}>
                                            <Link className={`font-bold whitespace-nowrap lg:px-2 px-1 py-2 lg:mx-1 mx-0 cursor-pointer animation-hover inline-block relative hover:text-rapide-red ${(scrollOnNav) ? 'text-rapide-yellow' : 'text-rapide-yellow'}`} href={nav.href} >
                                                {nav.name}
                                            </Link>
                                        </li>
                                    );
                                }
                            }
                            else if(nav.name == "Buy Tires"){
                                return(
                                    <li key={index}>
                                        <a className={`font-bold whitespace-nowrap lg:px-2 px-1 py-2 lg:mx-1 mx-0 cursor-pointer animation-hover inline-block relative hover:text-rapide-red ${(scrollOnNav) ? 'text-rapide-yellow' : 'text-rapide-yellow'}`} href={nav.href} target="_blank" rel="nofollow noreferrer" name={nav.name} aria-label={nav.name} >
                                            {nav.name}
                                        </a>
                                    </li>
                                )
                            }
                            else{
                                return (
                                    <li key={index}>
                                        <Link className={`font-bold whitespace-nowrap lg:px-2 px-1 py-2 lg:mx-1 mx-0 cursor-pointer animation-hover inline-block relative hover:text-rapide-red ${(scrollOnNav) ? 'text-rapide-yellow' : 'text-rapide-yellow'}`} href={nav.href} >
                                            {nav.name}
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                    <div className={`hidden lg:grid ml-auto grid-flow-col gap-4 ${router.route == "/" ? '!hidden' : ''}`}>
                        {!route_list_for_nav_fix.includes(router.route) && (
                            <button onClick={()=>setShowBookingDialog(true)} aria-label='book now' className={`whitespace-nowrap font-medium tracking-wide py-2 px-5 sm:px-8 border-2 text-white bg-rapide-red outline-none rounded capitalize hover:bg-rapide-yellow hover:text-black transition-all hover:shadow`}>
                                BOOK NOW
                            </button>
                        )}
                    </div>
                </nav>
                <nav ref={refNav} className={`${navPosFix ? 'grid': 'lg:hidden grid'} lg:hidden sticky max-w-screen-xl px-2 sm:px-8 lg:px-16 my-auto mx-auto grid-flow-col py-4`}>
                    <div className="">
                        <Link className="navbar-brand min-w-max" href="/">
                            <Image
                                src={'/assets/images/Rapide-Yellow-Logo_135.webp'}
                                alt={'rapide-logo'}
                                width={'135'}
                                height={'51'}
                            />
                        </Link>
                    </div>
                    <div className="font-medium flex justify-end items-center">
                        <div className={`${isOpen ? "hidden" : ""} ml-auto grid grid-flow-col gap-4`}>
                            {/*<Link aria-label="Check the prices of the services" href="/estimator" passHref>
                                <button aria-label="Check the prices of the services" type="button" className="md:flex hidden text-rapide-yellow hover:text-rapide-red px-2 py-1 border border-rapide-yellow focus:outline-none font-medium rounded-lg text-sm text-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 368 368" fill="#f7e332" strokeWidth="3" stroke="#f7e332">
                                        <path d="M328,16H40C18,16,0,34,0,56v256c0,22,18,40,40,40h288c22,0,40-18,40-40V56C368,34,350,16,328,16z M352,312,c0,13.2-10.8,24-24,24H40c-13.2,0-24-10.8-24-24V56c0-13.2,10.8-24,24-24h288c13.2,0,24,10.8,24,24V312z"/>
                                        <path d="M144,112h-32V80c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v32H64c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h32v32c0,4.4,3.6,8,8,8,c4.4,0,8-3.6,8-8v-32h32c4.4,0,8-3.6,8-8C152,115.6,148.4,112,144,112z"/>
                                        <path d="M296,112h-80c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h80c4.4,0,8-3.6,8-8C304,115.6,300.4,112,296,112z"/>
                                        <path d="M137.6,214c-3.2-3.2-8.4-3.2-11.2,0L104,236.8L81.6,214c-3.2-3.2-8-3.2-11.2,0s-3.2,8,0,11.2L93.2,248l-22.8,22.8,c-3.2,3.2-3.2,8,0,11.2c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4l22.4-22.8l22.4,22.8c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4,c3.2-3.2,3.2-8,0-11.2L115.2,248l22.8-22.8C140.8,222,140.8,217.2,137.6,214z"/>
                                        <path d="M296,256h-80c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h80c4.4,0,8-3.6,8-8C304,259.6,300.4,256,296,256z"/>
                                        <path d="M296,208h-80c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h80c4.4,0,8-3.6,8-8C304,211.6,300.4,208,296,208z"/>
                                    </svg>
                                    <div className="pl-1">
                                        <span className="block text-left">Estimator</span>
                                        <span className="text-white md:block hidden">Right service at the right price</span>
                                    </div>
                                </button>
                        </Link>*/}
                            {!route_list_for_nav_fix.includes(router.route) && (
                                <button onClick={()=>setShowBookingDialog(true)} aria-label='book now' className={`${router.route == "/" ? '!hidden' : ''} whitespace-nowrap font-medium tracking-wide py-2 px-5 sm:px-8 border-2 text-white bg-rapide-red outline-none rounded capitalize hover:bg-rapide-yellow hover:text-black transition-all hover:shadow`}>
                                    BOOK NOW
                                </button>
                            )}
                        </div>
                            {/*<button type="button" className="" onClick={() => setShowNav(!showNav)}>
                                <span className="text-rapide-yellow font-heading tracking-tight cursor-pointer transition-colors duration-200 rounded-full bg-crema-tint text-aubergine inline-flex items-center justify-center hover:underline focus:underline underline-offset-4 decoration-from-font font-medium w-10 h-10 md:w-11 md:h-11">
                                    <span className=" whitespace-nowrap break-all">
                                        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline text-current transform -translate-y-px w-5 h-5">
                                            <path d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                                        </svg>
                                    </span>
                                </span>
                            </button>*/}
                            <motion.nav
                                className={`lg:hidden inline-block pl-2 pt-2 pointer-events-auto ${isOpen ? "open fixed top-0 right-[-20px] w-[85vw] max-w-xs h-screen" : ""}`}
                                initial={false}
                                animate={isOpen ? "open" : "closed"}
                                >
                                    <div className={`relative z-10 pointer-events-auto ${isOpen ? "" : "hidden"}`} data-aria-hidden="true" aria-hidden="true">
                                        <div onClick={() => toggleOpen()} className="fixed inset-0 bg-black bg-opacity-40 w-screen h-screen opacity-50"></div>
                                    </div>
                                    <motion.div className={`z-20 bg-black ${isOpen ? "absolute top-0 left-0 bottom-0 w-[300px]" : ""}`} variants={sidebar}>
                                        <div className={`${isOpen ? "" : "hidden"}`}>
                                            <Navigation list={navigation} user={user} logout={logout} />
                                            <Subnavigation setShowBookingDialog={setShowBookingDialog} rl={route_list_for_nav_fix.includes(router.route)}/>
                                            <div className="h-16 bg-red-white"></div>
                                        </div>
                                    </motion.div>
                                    <MenuToggle aria-label="menu" className={`z-30 outline-none border-none rounded-full ${isOpen ? "absolute top-4 right-9" : ""}`} toggle={() => toggleOpen()} />
                            </motion.nav>
                    </div>
                </nav>
            </header>
        </Fragment>
    );
};

export default Navbar
