import Link from 'next/link';
import { Fragment } from 'react';

export const MenuItem = ({ i, item, user }) => {
    return (
        <li
            className="group -mt-px"
        >
            {user ? (
                <Link className="block hover:underline p-4 text-rapide-yellow" href={'/bookings'} >
                   Profile
                </Link>
            ) : (
                <Fragment>
                    {item.name == "Buy Tires" ? (
                        <a className="block hover:underline p-4 text-rapide-yellow" href={item.href} target="_blank" rel="nofollow noreferrer" name={item.name} aria-label={item.name} >
                            {item.name}
                        </a>
                    ): (
                        <Link className="block hover:underline p-4 text-rapide-yellow" href={item.href} >
                            {item.name}
                        </Link>
                    )}
                </Fragment>
            )}
        </li>
    );
};
